import React from 'react'
import { faDocker } from '@fortawesome/free-brands-svg-icons'

import Item from './Item'

const DockerIcon = () => {
  return (
    <Item
      backgroundColor="#FFF4F2"
      title="Docker"
      iconColor="#FF6C39"
      faIcon={faDocker}
    />
  )
}

export default DockerIcon
