import React from 'react'
import {
  Typography,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core'

import PageContainer from '../../components/PageContainer'
import Project from '../../types/Project'
import ImageGrid from './ImageGrid'

const styles = ({ breakpoints: { up } }: Theme) => {
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '3.1875rem',
      [xl]: {
        marginBottom: '6.9375rem',
      },
    },
    title: {
      marginBottom: '2.375rem',
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  existingScreenshots: Project['existingScreenshots']
}

const ExistingWebsite = ({ classes, existingScreenshots }: Props) => {
  if (!existingScreenshots || !existingScreenshots.length) return null
  return (
    <section className={classes.root}>
      <PageContainer>
        <Typography variant="h2" className={classes.title}>
          Existing Website
          <br />
          Screenshots
        </Typography>
        <ImageGrid images={existingScreenshots} />
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(ExistingWebsite)
