import React from 'react'
import { faNodeJs } from '@fortawesome/free-brands-svg-icons'

import Item from './Item'

const NodeIcon = () => {
  return (
    <Item
      backgroundColor="#F5F9EB"
      title="Node"
      iconColor="#77B268"
      faIcon={faNodeJs}
    />
  )
}

export default NodeIcon
