import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

import Project from '../../types/Project'
import PageContainer from '../../components/PageContainer'
import { handleImgNotFoundError } from '../../utils/errors'

interface BaseProps {
  nakedThumbnail: Project['nakedThumbnail']
  color: Project['color']
}

const getBackgroundColor = (props: BaseProps) => props.color

const styles = ({ breakpoints: { up } }: Theme) => {
  const paddingY: string = '3.375rem'
  return createStyles({
    root: {
      paddingTop: paddingY,
      paddingBottom: paddingY,
      backgroundColor: getBackgroundColor,
      marginBottom: '1.6875rem',
      [up('md')]: {
        marginBottom: '2rem',
      },
      [up('lg')]: {
        marginBottom: '3rem',
      },
      [up('xl')]: {
        marginBottom: '3.25rem',
      },
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
}

interface Props extends WithStyles<typeof styles>, BaseProps {}

const Thumbnail = ({ classes, nakedThumbnail }: Props) => {
  const image = getImage(nakedThumbnail.localFile)
  if (!image) return handleImgNotFoundError()
  return (
    <section className={classes.root}>
      <PageContainer className={classes.container}>
        <GatsbyImage image={image} alt={nakedThumbnail.alternativeText ?? ''} />
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(Thumbnail)
