import React from 'react'
import { faJsSquare } from '@fortawesome/free-brands-svg-icons'

import Item from './Item'

const JavaScriptIcon = () => {
  return (
    <Item
      backgroundColor="#3FB27F1A"
      title="JavaScript"
      iconColor="#3FB27F"
      faIcon={faJsSquare}
    />
  )
}

export default JavaScriptIcon
