import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Link,
  SvgIconTypeMap,
  Theme,
} from '@material-ui/core'

import { OverridableComponent } from '@material-ui/core/OverridableComponent'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const lg = up('lg')
  return createStyles({
    root: {
      listStyle: 'none',
      '& + &': {
        marginLeft: '1.8125rem',
        [lg]: {
          marginLeft: '2.4375rem',
        },
      },
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      color: 'black',
      textDecoration: 'underline',
      fontWeight: 500,
      fontSize: '0.8125rem',
      [md]: {
        fontSize: '1rem',
        lineHeight: '1.1719rem',
      },
    },
    icon: {
      width: '1.0625rem',
      marginRight: '0.5rem',
      [lg]: {
        marginRight: '0.6875rem',
        width: '1.5rem',
      },
    },
  })
}

type BaseIcon = SvgIconTypeMap<unknown, 'svg'>

interface Props extends WithStyles<typeof styles> {
  Icon: OverridableComponent<BaseIcon>
  href: string
  children: React.ReactNode
}

const Item = ({ classes, href, Icon, children }: Props) => {
  return (
    <li className={classes.root}>
      <Link className={classes.link} href={href}>
        <Icon className={classes.icon} color="primary" />
        {children}
      </Link>
    </li>
  )
}

export default withStyles(styles)(Item)
