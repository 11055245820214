import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import PageContainer from './PageContainer'
import RichText from './RichText'

const styles = ({ breakpoints: { up } }: Theme) => {
  const lg = up('lg')
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '3.1875rem',
      [lg]: {
        marginBottom: '4.5rem',
      },
      [xl]: {
        marginBottom: '6.9375rem',
      },
    },
    title: {
      marginBottom: '1.75rem',
      [xl]: {
        marginBottom: '5.25rem',
      },
    },
  })
}

export interface BaseProps {
  introduction?: string | null
  Container?: typeof PageContainer
  body?: React.ReactNode
  end?: React.ReactNode
}

interface Props extends WithStyles<typeof styles>, BaseProps {}

const Introduction = ({
  classes,
  introduction,
  Container = PageContainer,
  body,
  end = null,
}: Props) => {
  return (
    <section className={classes.root}>
      <Container>
        {body}
        {introduction && <RichText content={introduction} />}
        {end}
      </Container>
    </section>
  )
}

export default withStyles(styles)(Introduction)
