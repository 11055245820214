import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  Theme,
} from '@material-ui/core'

import PageContainer from './PageContainer'
import RichText from './RichText'
import { getPaddingY } from '../utils/styles'

const styles = ({ breakpoints: { up, only } }: Theme) => {
  const md = up('md')
  const xl = up('xl')

  const backgroundColor: string = 'rgba(15, 52, 143, 0.05)'

  return createStyles({
    root: {
      ...getPaddingY('2rem'),
      backgroundColor,
      [only('sm')]: {
        ...getPaddingY('0'),
        backgroundColor: 'transparent',
      },
      [md]: {
        ...getPaddingY('3rem'),
      },
      [xl]: {
        paddingTop: '6.875rem',
        paddingBottom: '9.25rem',
      },
      '& p': {
        color: '#707070',
      },
    },
    content: {
      [only('sm')]: {
        backgroundColor,
        padding: '3.625rem 1rem 2.1875rem',
      },
    },
    title: {
      marginBottom: '1.75rem',
      [md]: {
        marginBottom: '2.5rem',
      },
      [xl]: {
        marginBottom: '4.5625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  title: React.ReactNode
  content: string
  children?: React.ReactNode
  Container?: typeof PageContainer
}

const CardSection = ({
  classes,
  title,
  content,
  children,
  Container = PageContainer,
}: Props) => {
  return (
    <section className={classes.root}>
      <Container>
        <div className={classes.content}>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
          <RichText content={content} />
          {children}
        </div>
      </Container>
    </section>
  )
}

export default withStyles(styles)(CardSection)
