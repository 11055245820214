import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import ProjectResult from '../../../../types/ProjectResult'
import Item from './Item'
import { flexBreakpoint } from './styles'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      paddingLeft: 0,
      marginLeft: 0,
      marginBottom: 0,
      marginTop: '1.625rem',
      [up(flexBreakpoint)]: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '3rem',
        flexWrap: 'wrap',
      },
      [up('xl')]: {
        marginTop: '3.5625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  projectResults: ProjectResult[]
}

const List = ({ classes, projectResults }: Props) => {
  const renderItem = (
    projectResult: ProjectResult,
    index: number
  ): JSX.Element => (
    <Item
      {...projectResult}
      position={index}
      key={index}
      itemsAmount={projectResults.length}
    />
  )

  const renderedItems: JSX.Element[] = projectResults.map(renderItem)

  return <ul className={classes.root}>{renderedItems}</ul>
}

export default withStyles(styles)(List)
