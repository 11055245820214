import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  Theme,
} from '@material-ui/core'

import PageContainer from '../../components/PageContainer'
import GetAQuoteButton from '../../components/GetAQuoteButton'

const styles = ({
  palette: {
    text: { secondary: secondaryColor },
  },
  breakpoints: { up },
}: Theme) => {
  const lg = up('lg')
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '5rem',
      [xl]: {
        marginBottom: '8.9375rem',
      },
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column',
      textAlign: 'center',
    },
    title: {
      marginBottom: '0.8125rem',
      [xl]: {
        marginBottom: '1.375rem',
      },
    },
    description: {
      marginBottom: '1.4375rem',
      color: secondaryColor,
      [lg]: {
        marginBottom: '2.5rem',
      },
      [xl]: {
        marginBottom: '3.75rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const CallToAction = ({ classes }: Props) => {
  return (
    <section className={classes.root}>
      <PageContainer className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          Like what you saw?
        </Typography>
        <Typography variant="body1" className={classes.description}>
          Lets have a quick discussion on your project and we can get started.
        </Typography>
        <GetAQuoteButton />
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(CallToAction)
