import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import PageContainer from '../../components/PageContainer'
import Technologies from '../../components/Technologies'
import Project from '../../types/Project'
import Language from '../../types/Language'
import JavaScriptIcon from '../../components/Technologies/JavaScript'
import NodeIcon from '../../components/Technologies/NodeIcon'
import DockerIcon from '../../components/Technologies/DockerIcon'
import ReactIcon from '../../components/Technologies/React'
import Title from './Title'

const styles = ({ breakpoints: { up } }: Theme) => {
  const xl = up('xl')
  return createStyles({
    root: {
      [xl]: {
        marginBottom: '9rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  languages: Project['languages']
}

const ProjectTechnologies = ({ languages, classes }: Props) => {
  const languagesToIcons: Record<string, React.FunctionComponent> = {
    javascript: JavaScriptIcon,
    node: NodeIcon,
    docker: DockerIcon,
    react: ReactIcon,
  }

  const renderItem = (
    items: JSX.Element[],
    language: Language
  ): JSX.Element[] => {
    /**
     * This can be undefined because we don't have a way of guaranteeing that
     * there's an icon for each language. An admin could add a programming
     * language in Strapi, and we might not have the corresponding icon.
     */
    const Icon: React.FunctionComponent | undefined =
      languagesToIcons[language.slug]
    if (Icon) return [...items, <Icon key={language.slug} />]
    return items
  }

  const renderedItems: JSX.Element[] = languages.reduce(renderItem, [])

  if (!renderedItems.length) return null

  return (
    <section className={classes.root}>
      <PageContainer>
        <Title>
          Technologies Being
          <br />
          Used
        </Title>
        <Technologies>{renderedItems}</Technologies>
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(ProjectTechnologies)
