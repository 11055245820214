type Amount = number | string

export const getMarginX = (amount: Amount) => {
  return {
    marginLeft: amount,
    marginRight: amount,
  }
}

export const getMarginY = (amount: Amount) => {
  return {
    marginTop: amount,
    marginBottom: amount,
  }
}

export const getPaddingX = (amount: Amount) => {
  return {
    paddingLeft: amount,
    paddingRight: amount,
  }
}

export const getPaddingY = (amount: Amount) => {
  return {
    paddingTop: amount,
    paddingBottom: amount,
  }
}
