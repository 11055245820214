import React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  title: string
}

const Seo = ({ title }: Props) => {
  return (
    <Helmet>
      <title>Supercoder | {title}</title>
    </Helmet>
  )
}

export default Seo
