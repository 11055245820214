import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  Theme,
  Button,
} from '@material-ui/core'
import { OpenInNew } from '@material-ui/icons'

import BaseIntroduction from '../../components/Introduction'
import Project from '../../types/Project'
import Links from './Links'

const styles = ({ breakpoints: { up } }: Theme) => {
  const sm = up('sm')
  const md = up('md')
  const lg = up('lg')
  const xl = up('xl')

  const lineHeightMd = '112%'

  return createStyles({
    title: {
      marginBottom: '0.4375rem',
      fontSize: '1.75rem',
      lineHeight: '114%',
      [sm]: {
        fontSize: '2rem',
        lineHeight: '117%',
      },
      [md]: {
        fontSize: '3rem',
        lineHeight: lineHeightMd,
      },
      [lg]: {
        fontSize: '3.5rem',
        lineHeight: lineHeightMd,
        marginBottom: '1.5rem',
      },
      [xl]: {
        fontSize: '4.5rem',
        lineHeight: '116%',
      },
    },
    button: {
      marginTop: '1rem',
      [xl]: {
        padding: '0.85rem 2rem',
        fontSize: '1.25rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  title: Project['title']
  url: Project['url']
  github: Project['github']
  introduction: Project['introduction']
}

const Introduction = ({ classes, title, introduction, ...links }: Props) => {
  const body: React.ReactNode = (
    <>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
      <Links {...links} />
    </>
  )

  const renderWebsiteLink = (): React.ReactNode => {
    if (!links.url) return null
    return (
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        endIcon={<OpenInNew />}
        size="large"
        target="_blank"
        href={links.url}
      >
        View Website
      </Button>
    )
  }

  return (
    <BaseIntroduction
      introduction={introduction}
      body={body}
      end={renderWebsiteLink()}
    />
  )
}

export default withStyles(styles)(Introduction)
