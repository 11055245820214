import React from 'react'
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import Thumbnail from './Thumbnail'
import Project from '../../types/Project'
import ProblemStatement from './ProblemStatement'
import ExistingWebsite from './ExistingWebsite'
import ProposedSolution from './ProposedSolution'
import Content from './Content'
import Screenshots from './Screenshots'
import Results from './Results'
import CallToAction from './CallToAction'
import Seo from '../../components/Seo'
import Introduction from './Introduction'
import Technologies from './ProjectTechnologies'
import Video from './Video'
import { showSingleRowBreakpoint } from '../../components/Layout/Footer/Top/styles'

const styles = ({
  palette: {
    text: { secondary },
  },
  breakpoints: { up },
}: Theme) => {
  const md = up('md')
  const footerExpansionBk = up(showSingleRowBreakpoint)
  const xl = up('xl')

  return createStyles({
    content: {
      boxSizing: 'content-box',
      marginBottom: '6rem',
      [md]: {
        marginBottom: '8rem',
      },
      [footerExpansionBk]: {
        marginBottom: '10rem',
      },
      [xl]: {
        marginBottom: '12rem',
      },
      '& p': {
        color: secondary,
      },
    },
  })
}

type Data = {
  strapiProjects: Project
}

interface Props extends WithStyles<typeof styles> {
  data: Data
}

const ProjectPage = ({ classes, data: { strapiProjects: project } }: Props) => {
  const {
    nakedThumbnail,
    color,
    title,
    introduction,
    github,
    url,
    languages,
    introVideoUrl,
  } = project

  return (
    <Layout>
      <Seo title={title} />
      <div className={classes.content}>
        <Thumbnail nakedThumbnail={nakedThumbnail} color={color} />
        <Introduction
          title={title}
          introduction={introduction}
          github={github}
          url={url}
        />
        {introVideoUrl ? <Video url={introVideoUrl} /> : null}
        <ProblemStatement {...project} />
        <Technologies languages={languages} />
        <ExistingWebsite existingScreenshots={project.existingScreenshots} />
        <ProposedSolution solution={project.solution} />
        <Content content={project.content} />
        <Screenshots screenshots={project.screenshots} />
        <Results
          resultsDescription={project.resultsDescription}
          projectResults={project.projectResults}
        />
        <CallToAction />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProjectDetails($slug: String!) {
    strapiProjects(slug: { eq: $slug }) {
      title
      description
      introVideoUrl
      nakedThumbnail {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      url
      color
      content
      github
      existingScreenshots {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      featured
      introduction
      order
      problemStatement
      projectResults {
        description
        icon {
          localFile {
            publicURL
          }
        }
      }
      resultsDescription
      screenshots {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      solution
      client {
        name
      }
      category {
        name
      }
      languages {
        name
        slug
      }
    }
  }
`

export default withStyles(styles)(ProjectPage)
