import React from 'react'
import {
  Button,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  ButtonProps,
} from '@material-ui/core'
import { links } from '../contants/links'
import { Navigator, useNavigateToLink } from '../utils/navigate'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      [theme.breakpoints.down('sm')]: {
        borderRadius: '0.1875rem',
      },
      [theme.breakpoints.up('xl')]: {
        padding: '1.25rem 3.625rem',
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  color?: ButtonProps['color']
}

const GetAQuoteButton = ({ classes, color = 'primary' }: Props) => {
  const navigateToContactPage: Navigator = useNavigateToLink(links.contact)

  return (
    <Button
      variant="contained"
      color={color}
      classes={classes}
      onClick={navigateToContactPage}
    >
      Get a Quote
    </Button>
  )
}

export default withStyles(styles)(GetAQuoteButton)
