import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import CardSection from '../../../components/CardSection'
import Project from '../../../types/Project'
import List from './List'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '3.6875rem',
      [up('lg')]: {
        marginBottom: '4.5rem',
      },
      [up('xl')]: {
        marginBottom: '8.3125rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  resultsDescription: Project['resultsDescription']
  projectResults: Project['projectResults']
}

const Results = ({ classes, resultsDescription, projectResults }: Props) => {
  if (!resultsDescription) return null
  return (
    <CardSection
      classes={classes}
      title={
        <>
          Project
          <br />
          Results
        </>
      }
      content={resultsDescription}
    >
      {projectResults && <List projectResults={projectResults} />}
    </CardSection>
  )
}

export default withStyles(styles)(Results)
