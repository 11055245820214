import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import CardSection from '../../components/CardSection'
import Project from '../../types/Project'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '3.0625rem',
      [up('xl')]: {
        marginBottom: '7.875rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  solution: Project['solution']
}

const ProposedSolution = ({ classes, solution }: Props) => {
  if (!solution) return null
  return (
    <CardSection
      classes={classes}
      title={
        <>
          Proposed
          <br />
          Solution
        </>
      }
      content={solution}
    />
  )
}

export default withStyles(styles)(ProposedSolution)
