import React from 'react'
import { Grid } from '@material-ui/core'

interface Props {
  children: React.ReactNode
}

const Technologies = ({ children }: Props) => {
  return <Grid container>{children}</Grid>
}

export default Technologies
