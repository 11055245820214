import React from 'react'
import { faReact } from '@fortawesome/free-brands-svg-icons'

import Item from './Item'

const ReactIcon = () => {
  return (
    <Item
      backgroundColor="#EAF5F9"
      title="React"
      iconColor="#005aa3"
      faIcon={faReact}
    />
  )
}

export default ReactIcon
