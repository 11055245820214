import React from 'react'
import { withStyles, WithStyles, createStyles } from '@material-ui/core'

import Item from './Item'
import { Image } from '../../../types/Image'

const styles = () => {
  return createStyles({
    root: {
      padding: 0,
      margin: 0,
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  images: Image[]
}

const ImageGrid = ({ classes, images }: Props) => {
  const renderImage = (image: Image, index: number): JSX.Element => (
    <Item image={image} key={index} />
  )

  const renderedImages: JSX.Element[] = images.map(renderImage)

  return <ul className={classes.root}>{renderedImages}</ul>
}

export default withStyles(styles)(ImageGrid)
