import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import PageContainer from '../../components/PageContainer'
import Project from '../../types/Project'
import RichText from '../../components/RichText'

const styles = ({ breakpoints: { up } }: Theme) => {
  const lg = up('lg')
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '2.0625rem',
      [lg]: {
        marginBottom: '4.5rem',
      },
      [xl]: {
        marginBottom: '11rem',
      },
      '& img': {
        width: '100%',
        marginBottom: '3.125rem',
        [xl]: {
          marginBottom: '5rem',
        },
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  content: Project['content']
}

const Content = ({ classes, content }: Props) => {
  if (!content) return null
  return (
    <section className={classes.root}>
      <PageContainer>
        <RichText content={content} />
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(Content)
