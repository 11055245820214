import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  Theme,
} from '@material-ui/core'

import { getSizeStyles } from '../../../../utils/size'
import ProjectResult from '../../../../types/ProjectResult'
import { flexBreakpoint } from './styles'

interface BaseProps extends ProjectResult {
  itemsAmount: number
  position: number
}

const styles = ({ breakpoints: { up } }: Theme) => {
  const flexBreakpointUp = up(flexBreakpoint)
  const md = up('md')
  const lg = up('lg')
  const xl = up('xl')

  const getIconContainerMarginBottom = ({
    itemsAmount,
    position,
  }: BaseProps) => {
    const secondLastIndex: number = itemsAmount - 2
    const lastIndex: number = itemsAmount - 1
    const isEven: boolean = itemsAmount % 2 === 0
    if (
      (isEven && position < secondLastIndex) ||
      (!isEven && position < lastIndex)
    ) {
      return '4.5625rem'
    }
    return 0
  }

  return createStyles({
    root: {
      listStyle: 'none',
      display: 'flex',
      alignItems: 'center',
      [flexBreakpointUp]: {
        marginBottom: getIconContainerMarginBottom,
        width: '48%',
      },
      '& + &': {
        marginTop: '1.125rem',
        [flexBreakpointUp]: {
          marginTop: 0,
        },
      },
    },
    iconContainer: {
      ...getSizeStyles('5.0625rem'),
      flexShrink: 0,
      marginRight: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(15, 105, 201, 0.07)',
      borderRadius: '0.8125rem',
      [lg]: {
        ...getSizeStyles('8rem'),
      },
      [xl]: {
        ...getSizeStyles('10.3125rem'),
        borderRadius: '1.4375rem',
      },
    },
    icon: {
      ...getSizeStyles('1.5625rem'),
      [lg]: {
        ...getSizeStyles('2.8rem'),
      },
      [xl]: {
        ...getSizeStyles('3.125rem'),
      },
    },
    text: {
      fontWeight: 400,
      fontSize: '0.8125rem',
      lineHeight: '1.0938rem',
      color: '#707070',
      [md]: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      [lg]: {
        fontSize: '1.25rem',
        lineHeight: '2.1rem',
        width: '17.625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, BaseProps {}

const Item = ({
  classes,
  icon: { localFile, alternativeText },
  description,
}: Props) => {
  return (
    <li className={classes.root}>
      <div className={classes.iconContainer}>
        <img
          src={localFile.publicURL}
          className={classes.icon}
          alt={alternativeText}
        />
      </div>
      <Typography variant="body1" className={classes.text}>
        {description}
      </Typography>
    </li>
  )
}

export default withStyles(styles)(Item)
