import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import CardSection from '../../components/CardSection'
import Project from '../../types/Project'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '2.375rem',
      [up('lg')]: {
        marginBottom: '4.5rem',
      },
      [up('xl')]: {
        marginBottom: '7.875rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, Project {}

const ProblemStatement = ({ classes, problemStatement }: Props) => {
  if (!problemStatement) return null
  return (
    <CardSection
      classes={classes}
      title={
        <>
          Problem
          <br />
          Statement
        </>
      }
      content={problemStatement}
    />
  )
}

export default withStyles(styles)(ProblemStatement)
