import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  Theme,
  Grid,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { getSizeStyles } from '../../utils/size'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const xl = up('xl')
  return createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      listStyle: 'none',
      marginBottom: '1.8125rem',
      [md]: {
        marginBottom: '2.875rem',
      },
      [xl]: {
        marginBottom: 0,
      },
    },
    iconContainer: {
      marginRight: '0.4375rem',
      ...getSizeStyles('1.625rem'),
      backgroundColor: '#EAF5F9',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [md]: {
        ...getSizeStyles('3.4375rem'),
        marginRight: '0.9375rem',
      },
      [xl]: {
        ...getSizeStyles('4.125rem'),
      },
    },
    icon: {
      fontSize: '1rem',
      lineHeight: '2rem',
      [md]: {
        fontSize: '1.375rem',
      },
      [xl]: {
        fontSize: '1.6875rem',
      },
    },
    title: {
      [md]: {
        fontSize: '1.25rem',
        lineHeight: '2.25rem',
      },
    },
  })
}

export interface BaseProps {
  title: string
  backgroundColor: string
  faIcon: IconProp
  iconColor: string
}

interface Props extends WithStyles<typeof styles>, BaseProps {}

const Item = ({
  classes,
  title,
  backgroundColor,
  faIcon,
  iconColor,
}: Props) => {
  return (
    <Grid item xs={6} md={4} xl={3} className={classes.root}>
      <div className={classes.iconContainer} style={{ backgroundColor }}>
        <FontAwesomeIcon
          icon={faIcon}
          className={classes.icon}
          style={{ color: iconColor }}
        />
      </div>
      <Typography variant="h3" className={classes.title}>
        {title}
      </Typography>
    </Grid>
  )
}

export default withStyles(styles)(Item)
