import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import GitHub from '@material-ui/icons/GitHub'

import Item from './Item'
import Project from '../../../types/Project'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '0.8125rem',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 0,
      [up('lg')]: {
        marginBottom: '2.25rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  github: Project['github']
}

const Links = ({ classes, github }: Props) => {
  if (!github) return null

  return (
    <ul className={classes.root}>
      {github && (
        <Item Icon={GitHub} href={github}>
          GitHub Repository
        </Item>
      )}
    </ul>
  )
}

export default withStyles(styles)(Links)
