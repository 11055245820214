import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  Theme,
} from '@material-ui/core'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '1.75rem',
      [md]: {
        marginBottom: '2.5rem',
      },
      [xl]: {
        marginBottom: '4.5625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
}

const Title = ({ classes, children }: Props) => {
  return (
    <Typography variant="h2" className={classes.root}>
      {children}
    </Typography>
  )
}

export default withStyles(styles)(Title)
