import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core'
import React from 'react'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const lg = up('lg')
  return createStyles({
    root: {
      marginTop: '2rem',
      marginBottom: '1rem',
      [md]: {
        marginTop: '3rem',
        marginBottom: '2rem',
      },
      [lg]: {
        marginTop: '4rem',
        marginBottom: '3rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  url: string
}

function Video({ classes, url }: Props) {
  return (
    <Box className={classes.root}>
      <video width="100%" controls autoPlay muted loop playsInline>
        <source src={url} type="video/mp4" />
      </video>
    </Box>
  )
}

export default withStyles(styles)(Video)
