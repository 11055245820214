import React from 'react'
import { withStyles, WithStyles, createStyles } from '@material-ui/core'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Image } from '../../../types/Image'
import { handleImgNotFoundError } from '../../../utils/errors'

const styles = () => {
  return createStyles({
    root: {
      listStyle: 'none',
      '& + &': {
        marginTop: '1.3125rem',
      },
    },
    image: {
      width: '100%',
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  image: Image
}

const Item = ({
  classes,
  image: { localFile, alternativeText: alt },
}: Props) => {
  const image = getImage(localFile)

  if (!image) return handleImgNotFoundError()

  return (
    <li className={classes.root}>
      <GatsbyImage className={classes.image} image={image} alt={alt ?? ''} />
    </li>
  )
}

export default withStyles(styles)(Item)
