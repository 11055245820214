import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core'

import Project from '../../types/Project'
import PageContainer, {
  containerPaddingX,
} from '../../components/PageContainer'
import ImageGrid from './ImageGrid'
import { getPaddingX } from '../../utils/styles'

const styles = ({ breakpoints: { up, between } }: Theme) => {
  const lg = up('lg')
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '4.0625rem',
      [xl]: {
        marginBottom: '11rem',
      },
    },
    screenshotsContainer: {
      [between('xs', 'md')]: {
        ...getPaddingX('0'),
        width: '100%',
      },
      [lg]: {
        ...getPaddingX(containerPaddingX.md),
      },
    },
    title: {
      marginBottom: '2.0625rem',
      [xl]: {
        marginBottom: '2.25rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  screenshots: Project['screenshots']
}

const Screenshots = ({ classes, screenshots }: Props) => {
  if (!screenshots || !screenshots.length) return null
  return (
    <section className={classes.root}>
      <PageContainer>
        <Typography variant="h2" className={classes.title}>
          Screenshots
        </Typography>
      </PageContainer>
      <PageContainer className={classes.screenshotsContainer}>
        <ImageGrid images={screenshots} />
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(Screenshots)
